<template>
  <Sidebar
    v-model:visible="showSidebar"
    :dismissable="false"
    :showCloseIcon="false"
    position="right"
    class="p-sidebar-md"
    @keydown.esc="closeSidebar"
  >
    <h5 class="card-title">{{ $t("MESSAGE.sending_message") }}</h5>
    <form @submit.prevent="handleSubmit(!v$.$invalid)" novalidate>
      <div class="row">
        <div class="mb-3">
          <label :for="model.to" class="form-label">
            {{ $t("MESSAGE.separated_list_of_recipients") }}
          </label>
          <Chips
            v-model="model.to"
            separator=","
            :allowDuplicate="false"
            :addOnBlur="true"
            :class="this.getInputClass(v$.model.to)"
          />
          <validate-error :field="v$.model.to" :submitted="submitted" />
        </div>
        <div class="mb-3">
          <label :for="model.from" class="form-label">
            {{ $t("CAMPAIGN.Sender") }}
          </label>
          <select
            v-model="model.from"
            class="form-select"
            :class="this.getInputClass(v$.model.from)"
          >
            <option
              v-for="sender in senderAliasList.list"
              :key="sender.id"
              :value="sender.name"
            >
              {{ sender.name }}
            </option>
          </select>
          <validate-error :field="v$.model.from" :submitted="submitted" />
        </div>
        <div class="mb-3">
          <label :for="template" class="form-label">
            {{ $t("CAMPAIGN.Template") }}
          </label>
          <select v-model="template" @change="setTemplate" class="form-select">
            <option></option>
            <option
              v-for="template in templateList.list"
              :key="template.id"
              :value="template.id"
            >
              {{ template.name }}
            </option>
          </select>
        </div>

        <div class="mb-3">
          <label :for="model.message" class="form-label">
            {{ $t("CAMPAIGN.Message") }}
          </label>
          <textarea
            rows="6"
            class="form-control"
            :id="model.message"
            :class="getInputClass(v$.model.message)"
            autocomplete="off"
            v-model="model.message"
          ></textarea>
          <rock-sms-info-badge :text="model.message" />
          <validate-error :field="v$.model.message" :submitted="submitted" />
        </div>

        <div class="input-group mb-3">
          <div class="form-check form-switch">
            <input
              v-model="delay"
              class="form-check-input large-check"
              type="checkbox"
              id="notify-me"
            />
            <label class="form-check-label m-r-xs" for="notify-me">
              {{ $t("MESSAGE.delayed_sending") }}
            </label>
          </div>
        </div>

        <div v-if="delay" class="mb-3">
          <label :for="model.text" class="form-label">
            {{ $t("MESSAGE.sending_time") }}
          </label>
          <date-picker
            v-model="model.sendTime"
            :min-date="new Date()"
            :popover="false"
            :rules="datetimeRules"
            title-position="left"
            color="indigo"
            mode="dateTime"
            is24hr
          >
            <template v-slot="{ togglePopover, inputValue, inputEvents }">
              <input
                class="form-control"
                :value="inputValue"
                v-on="inputEvents"
                @click="togglePopover()"
              />
            </template>
          </date-picker>
        </div>

        <div class="mt-3">
          <button
            type="button"
            class="btn btn-secondary m-b-xs m-r-xs"
            data-bs-dismiss="modal"
            @click="closeSidebar"
          >
            {{ $t("COMMON.close") }}
          </button>

          <rock-submit-button
            :processing="this.processing"
            :text="$t('COMMON.send')"
            @click="handleSubmit(!v$.$invalid)"
          />
        </div>
      </div>
    </form>
  </Sidebar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { DatePicker } from "v-calendar";
import { required } from "@/utils/i18n-validators";
import messageService from "@/services/messageService";
import Sidebar from "primevue/sidebar";
import Chips from "primevue/chips";
import RockSmsInfoBadge from "../dumb-components/rockSmsInfoBadge";
import ValidateError from "../../components/dumb-components/validateError";
import RockSubmitButton from "../dumb-components/rockSubmitButton";

export default {
  name: "message-form",
  props: ["id", "display"],
  components: {
    RockSubmitButton,
    RockSmsInfoBadge,
    Sidebar,
    Chips,
    ValidateError,
    DatePicker,
  },
  setup: () => ({ v$: useVuelidate({ $autoDirty: true }) }),
  data: () => ({
    showSidebar: false,
    template: "",
    submitted: false,
    processing: false,
    vuelidateExternalResults: [],
    delay: false,
    datetimeRules: {
      hours: { min: 9, max: 18 },
      minutes: { interval: 5 },
    },
    model: {
      to: null,
      from: null,
      message: "",
      sendTime: new Date(),
    },
  }),
  validations() {
    return {
      model: {
        to: { required },
        from: { required },
        message: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      senderAliasList: "senderAlias/list",
      templateList: "template/list",
    }),
  },
  methods: {
    ...mapActions({
      getSenderAliasList: "senderAlias/getList",
      getTemplateList: "template/getList",
    }),
    getInputClass(field) {
      return field.$invalid && this.submitted ? "invalid" : "";
    },
    setTemplate() {
      const template = this.templateList.list.filter(
        (item) => item.id === this.template
      );
      this.model.message = template[0].text;
    },
    closeSidebar() {
      this.showSidebar = false;
      this.$emit("clickClose", false);
    },
    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.processing = true;

      messageService.send(this.model).then(
        () => {
          this.submitted = false;
          this.processing = false;
          this.model.to = null;

          this.$toast.add({
            severity: "success",
            summary: "Успех!",
            detail: "Сообщение отправлено.",
            life: 3000,
          });
        },
        (fail) => {
          Object.assign(this.vuelidateExternalResults, {
            model: fail.errors,
          });

          this.processing = false;
        }
      );
    },
  },
  beforeUpdate() {
    this.showSidebar = this.display;
    this.model.message = null;

    this.getSenderAliasList({ status: 1 }).then(() => {
      if (this.senderAliasList.list.length === 1) {
        this.model.from = this.senderAliasList.list[0].name;
      }
    });

    this.getTemplateList({ perPage: 50 });
  },
};
</script>

<style scoped lang="scss">
.p-chips {
  display: block;
}
</style>

<template>
  <span @click="toggle" class="sms-count badge bg-secondary text-dark">
    <b>{{ info.msgCount }} SMS</b> ({{ info.charCount }}/{{
      info.charsPerSegment * info.msgCount
    }})
  </span>

  <OverlayPanel ref="op" :dismissable="true" :style="{ width: '530px' }">
    <template v-for="(char, index) in this.text" :key="index">
      <span v-if="char === ' '" class="char badge bg-secondary">&nbsp;</span>
      <div v-else-if="char === '\n'"></div>
      <span v-else class="char badge" :class="isGsmClass(char)">
        {{ char }}
      </span>
    </template>

    <div class="legend">
      <ul>
        <li>
          <span class="char badge bg-secondary">G</span>
          <span class="text">GSM - 160 {{ $t("COMMON.chars") }} в 1 SMS</span>
        </li>
        <li>
          <span class="char badge bg-danger">U</span>
          <span class="text">UCS2 - 70 {{ $t("COMMON.chars") }} 1 SMS</span>
        </li>
      </ul>
    </div>
  </OverlayPanel>
</template>

<script>
import OverlayPanel from "primevue/overlaypanel";
import * as GsmCharsetUtils from "@trt2/gsm-charset-utils";

export default {
  name: "rock-sms-info-badge",
  components: { OverlayPanel },
  props: ["text"],
  emits: ["count"],
  computed: {
    info() {
      if (this.text) {
        return GsmCharsetUtils.getCharCount(this.text);
      } else {
        return { msgCount: 1, charCount: 0, charsPerSegment: 160 };
      }
    },
  },
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    isGsmClass(char) {
      return GsmCharsetUtils.isGsmChar(char) ? "bg-secondary" : "bg-danger";
    },
  },
  watch: {
    "info.msgCount": function () {
      this.$emit("count", this.info.msgCount);
    },
  },
};
</script>

<style scoped>
.sms-count {
  float: right;
  margin-top: 5px;
  letter-spacing: 0.5px;
  cursor: pointer;

  i {
    vertical-align: bottom;
    margin-right: 3px;
  }
}

.char {
  padding: 7px 6px;
  margin: 2px;
}

.legend {
  margin-top: 20px;
}
ul {
  padding-left: 0;
}
ul li {
  list-style: none;
}
ul li .text {
  font-size: 13px;
  margin-left: 5px;
}
</style>
